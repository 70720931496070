import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate, useLocation } from '@reach/router';

import { ROUTES } from 'constants/common';
import Search from 'images/blog/search.inline.svg';

import {
  SearchInput,
  SearchWrapper,
  Tag,
  TagsList,
  TagsWrapper,
  Wrapper,
} from './styled';

type Tag = {
  count: number;
  id: string;
  name: string;
  slug: string;
};

type TagsPayload = {
  allWpTag: {
    nodes: Tag[];
  };
};

const TagsHeader = () => {
  const { pathname, search } = useLocation();
  const queryObj = new URLSearchParams(search);
  const tag = queryObj.get('tag');
  const [val, setVal] = useState('');
  const { allWpTag } = useStaticQuery<TagsPayload>(graphql`
    {
      allWpTag(
        sort: { fields: count, order: DESC }
        filter: { count: { gte: 1 } }
      ) {
        nodes {
          count
          id
          name
          slug
        }
      }
    }
  `);
  const tags = allWpTag.nodes.filter((t) => {
    if (t.slug === tag) {
      return false;
    }

    if (!val) {
      return true;
    }

    if (t.name.includes(val)) {
      return true;
    }

    return false;
  });
  const activeTag = allWpTag.nodes.find((t) => t.slug === tag);

  return (
    <Wrapper>
      <SearchWrapper>
        <SearchInput
          placeholder="search by tags"
          onChange={(e) => setVal(e.target.value)}
          value={val}
        />
        <Search />
      </SearchWrapper>

      <TagsWrapper>
        <TagsList searching={Boolean(val)}>
          {activeTag && <Tag active>{activeTag.name}</Tag>}
          {!val && (
            <Tag
              active={!tag && pathname === ROUTES.blog}
              onClick={() => navigate(ROUTES.blog)}
            >
              newest
            </Tag>
          )}
          {tags.map((t) => (
            <Tag
              key={t.id}
              onClick={() => {
                navigate(`${ROUTES.blog}?tag=${t.slug}`);
                setVal('');
              }}
            >
              {t.name}
            </Tag>
          ))}
        </TagsList>
      </TagsWrapper>
    </Wrapper>
  );
};

export default TagsHeader;
