import styled from 'styles/styled';
import { Wrapper as BlogCardWrapper } from 'components/BlogCard/styled';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
  padding: 0 18px;
  margin: 0 auto 100px;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  width: 100%;

  > button {
    margin-bottom: 1.25rem;

    p {
      font-size: ${({ theme }) => theme.fontSize.title};
    }
  }

  ${BlogCardWrapper} {
    margin: 0;
  }

  @media ${({ theme }) => theme.devices.large} {
    padding: 0;
    margin: 0 auto 100px;

    video {
      left: 0;
      width: 100vw;
    }
  }
`;

export const Articles = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 22px;
`;

export const PostWrapper = styled.div`
  > a {
    display: block;
    height: 100%;
  }

  &:first-child {
    grid-column: 1 / -1;
  }
`;

export const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const Page = styled(Link)<{ active: boolean }>`
  color: ${({ active, theme }) => (active ? '#fff' : theme.palette.blogAccent)};
  font-size: 18px;
  position: relative;
  text-align: center;

  &::before {
    background-color: #fff;
    content: ${({ active }) => (active ? '""' : 'none')};
    height: 2px;
    left: -2px;
    position: absolute;
    right: -2px;
    top: calc(100% + 2px);
  }

  & + & {
    margin-left: 15px;
  }
`;

export const ChangePage = styled(Link)`
  cursor: pointer;
  height: 0.8em;
  position: relative;
  width: 0.8em;

  &:disabled {
    cursor: not-allowed;
  }

  &::before {
    border-left: 2px solid ${({ theme }) => theme.palette.blogAccent};
    border-top: 2px solid ${({ theme }) => theme.palette.blogAccent};
    border-radius: 2px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }

  &:first-child {
    margin-right: 15px;
    transform: rotateZ(-45deg);
  }

  &:last-child {
    margin-left: 15px;
    transform: rotateZ(135deg);
  }
`;
