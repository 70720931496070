import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { theme } from 'styles/styled';
import homeBg from 'images/background/home-bg.jpg';
import { ROUTES } from 'constants/common';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Blog from 'components/Blog';

const BlogPage = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "blog" }) {
        ...MetaData
      }
    }
  `);

  wpPage.seo.opengraphUrl = ROUTES.blog;

  return (
    <Layout backgroundColor={theme.palette.dark} backgroundImage={homeBg}>
      <SEO metadata={wpPage || {}} />
      <Blog />
    </Layout>
  );
};

export default BlogPage;
