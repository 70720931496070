import styled from 'styles/styled';

export const Wrapper = styled.div`
  margin: 0 auto 20px;
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;

  @media ${({ theme }) => theme.devices.medium} {
    display: flex;
    margin-bottom: 50px;
  }
`;

export const TagsWrapper = styled.div`
  background-color: #012f5a;
  border-radius: 10px;
  line-height: 1.4;
  flex-grow: 1;
  padding: 15px 20px;
`;

export const TagsList = styled.ul<{ searching: boolean }>`
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: space-between;
  justify-content: ${({ searching }) =>
    searching ? 'flex-start' : 'space-between'};
  max-height: 1.3em;
  overflow: hidden;
`;

export const Tag = styled.li<{ active?: boolean }>`
  color: ${({ active, theme }) =>
    theme.palette[active ? 'light' : 'blogAccent']};
  cursor: pointer;
  font-size: inherit;
  font-weight: ${({ active }) => (active ? 700 : 400)};

  & + & {
    margin-left: 15px;
  }

  @media ${({ theme }) => theme.devices.large} {
    & + & {
      margin-left: 30px;
    }
  }
`;

export const SearchWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;

  svg {
    color: ${({ theme }) => theme.palette.blogAccent};
    height: 1em;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: ${({ theme }) => theme.ui.transition('color')};
    width: 1em;
  }

  @media ${({ theme }) => theme.devices.medium} {
    margin-left: 20px;
    margin-bottom: 0;
    order: 1;
  }
`;

export const SearchInput = styled.input`
  background-color: transparent;
  border: 1px solid #005aa0;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.blogAccent};
  line-height: 1.4;
  min-width: 200px;
  transition: ${({ theme }) => theme.ui.transition('all')};
  padding: 15px 40px 15px 20px;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.palette.light};
    color: ${({ theme }) => theme.palette.light};
    outline: none;

    &::placeholder {
      color: ${({ theme }) => theme.palette.light};
    }

    & + svg {
      color: ${({ theme }) => theme.palette.light};
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.blogAccent};
    transition: ${({ theme }) => theme.ui.transition('color')};
  }
`;
