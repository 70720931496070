import styled from 'styles/styled';

export const TagLi = styled.li`
  border: 1px solid ${({ theme }) => theme.palette.blogAccent};
  color: ${({ theme }) => theme.palette.blogAccent};
  cursor: pointer;
  border-radius: 1em;
  line-height: 1;
  padding: 4px 10px;
`;
