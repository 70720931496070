import styled from 'styles/styled';

import { Button } from 'components/UI/Button';

export const Wrapper = styled.article<{ horizontal: boolean }>`
  background-color: rgba(0, 90, 160, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: ${({ horizontal }) => (horizontal ? 'auto' : '100%')};
  overflow: hidden;

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  }
`;

export const ImgWrapper = styled.div<{ horizontal: boolean }>`
  flex-basis: ${({ horizontal }) => (horizontal ? '50%' : 'auto')};
  flex-grow: 0;
  flex-shrink: 0;

  .gatsby-image-wrapper {
    height: ${({ horizontal }) => (horizontal ? '100%' : 'auto')};
  }
`;

export const Image = styled.img`
  object-fit: cover;
  height: 100%;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.smallBase};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 15px;

  @media ${({ theme }) => theme.devices.large} {
    font-size: ${({ theme }) => theme.fontSize.subtitleMobile};
  }
`;

export const ExcerptWrapper = styled.div`
  color: ${({ theme }) => theme.palette.light};
`;

export const StyledButton = styled(Button)`
  margin-top: 30px;
  padding: 10px 40px;
  text-transform: uppercase;
`;

export const InfoWrapper = styled.div<{ horizontal: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px;

  @media ${({ theme }) => theme.devices.medium} {
    padding: ${({ horizontal }) => (horizontal ? '30px' : '15px')};
  }
`;

export const BottomWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: 10px;

  p {
    color: ${({ theme }) => theme.palette.blogAccent};
    font-weight: 400;
  }
`;

export const TagsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0 25px;
`;
