import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import { ROUTES } from 'constants/common';
import { BlogTypes } from 'types';

import BlogCard from 'components/BlogCard';
import TagsHeader from 'components/TagsHeader';

import {
  Wrapper,
  Articles,
  PostWrapper,
  Page,
  PaginationWrapper,
  ChangePage,
} from './styled';

// type Tag = BlogTypes.Tag;
type BlogPost = BlogTypes.BlogPost;

const POSTS_PER_PAGE = 10;

const pageFilter = (index: number, currentPage: number, pages: number) => {
  if (index === 0) {
    return true;
  }

  if (index === pages - 1) {
    return true;
  }

  if (index >= currentPage - 1 && index <= currentPage + 1) {
    return true;
  }

  return false;
};

const Blog = () => {
  const { search } = useLocation();
  const queryObj = new URLSearchParams(search);
  const page = parseInt(queryObj.get('page') || '1');
  const tag = queryObj.get('tag');
  const {
    blogCategory,
  }: // tags,
  {
    blogCategory: { nodes: BlogPost[] };
    tags: { nodes: { name: string; slug: string }[] };
  } = useStaticQuery(graphql`
    query Blog {
      blogCategory: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Blog" } } } }
        }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          ...BlogCardFragment
        }
      }

      tags: allWpTag {
        nodes {
          id
          name
          slug
        }
      }
    }
  `);

  const blogPosts = blogCategory.nodes.filter(
    (p) => !tag || p.tags.nodes.some((t) => t.slug === tag),
  );
  const pagesNum = Math.ceil(blogPosts.length / POSTS_PER_PAGE);
  const pageStart = 0 + POSTS_PER_PAGE * (page - 1);
  const pageEnd = pageStart + POSTS_PER_PAGE;
  const tagQuery = tag ? `&tag=${tag}` : '';
  const nextPageQuery = `?page=${Math.min(page + 1, pagesNum)}${tagQuery}`;
  const prevPageQuery = page > 2 ? `?page=${page - 1}${tagQuery}` : '';

  return (
    <Wrapper>
      <TagsHeader />
      <Articles>
        {blogPosts
          .slice(pageStart, pageEnd)
          .map(
            (
              { id, slug, featuredImage, title, date, tags, author, excerpt },
              i,
            ) => {
              return (
                <PostWrapper key={id}>
                  <BlogCard
                    image={featuredImage?.node.localFile.childImageSharp}
                    title={title}
                    slug={slug}
                    date={date}
                    tags={tags.nodes}
                    author={author.node.name}
                    horizontal={i === 0}
                    excerpt={excerpt}
                  />
                </PostWrapper>
              );
            },
          )}
      </Articles>

      {pagesNum > 1 && (
        <PaginationWrapper>
          <ChangePage to={`${ROUTES.blog}${prevPageQuery}`} />
          {Array.from({ length: pagesNum })
            .map((_, i) => i)
            .filter((_, i) => pageFilter(i, page - 1, pagesNum))
            .map((i) => {
              const pageNum = i + 1;
              const pageQuery = i ? `?page=${pageNum}${tagQuery}` : '';
              return (
                <Page
                  key={i}
                  to={`${ROUTES.blog}${pageQuery}`}
                  active={pageNum === page}
                >
                  {pageNum.toString().padStart(2, '0')}
                </Page>
              );
            })}
          <ChangePage to={`${ROUTES.blog}${nextPageQuery}`} />
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

export default Blog;
