import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { Link } from 'gatsby';

import { ROUTES } from 'constants/common';
import placeholderImage from 'images/blog-placeholder.jpg';

import Tag from 'components/Tag';
import {
  Title,
  Wrapper,
  Image,
  InfoWrapper,
  BottomWrapper,
  TagsWrapper,
  ImgWrapper,
  ExcerptWrapper,
  StyledButton,
} from './styled';

interface Props {
  image?: {
    fluid: FluidObject;
  };
  title: string;
  slug: string;
  date: string;
  author: string;
  tags?: { id: string; name: string; slug: string }[];
  horizontal?: boolean;
  excerpt?: string;
  short?: boolean;
}

const BlogCard = ({
  author,
  horizontal = false,
  image,
  title,
  date,
  slug,
  tags,
  excerpt,
}: Props) => (
  <Wrapper horizontal={horizontal}>
    <ImgWrapper horizontal={horizontal}>
      <Link to={`${ROUTES.blog}/${slug}`}>
        {image ? (
          <Img fluid={{ ...image.fluid, aspectRatio: 6 / 3.5 }} alt={title} />
        ) : (
          <Image src={placeholderImage} />
        )}
      </Link>
    </ImgWrapper>
    <InfoWrapper horizontal={horizontal}>
      {!!tags?.length && (
        <TagsWrapper>
          {tags.map((tag) => (
            <Tag key={tag.id} name={tag.name} slug={tag.slug} />
          ))}
        </TagsWrapper>
      )}
      <Link to={`${ROUTES.blog}/${slug}`}>
        <Title>{title}</Title>
      </Link>
      {horizontal && excerpt && (
        <ExcerptWrapper dangerouslySetInnerHTML={{ __html: excerpt }} />
      )}
      <BottomWrapper>
        <p>by {author}</p>
        <p>{date}</p>
      </BottomWrapper>
      {horizontal && (
        <Link to={`${ROUTES.blog}/${slug}`}>
          <StyledButton>Read more</StyledButton>
        </Link>
      )}
    </InfoWrapper>
  </Wrapper>
);

export default BlogCard;
