import React from 'react';
import { navigate } from '@reach/router';

import { ROUTES } from 'constants/common';

import { TagLi } from './styled';

interface Props {
  name: string;
  slug: string;
}

const Tag = ({ name, slug }: Props) => {
  return (
    <TagLi onClick={() => navigate(`${ROUTES.blog}?tag=${slug}`)}>{name}</TagLi>
  );
};

export default Tag;
